import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 14 14">
    <path
      fill={color}
      d="M7,0C3.1,0,0,3.1,0,7c0,3.9,3.1,7,7,7c3.9,0,7-3.1,7-7C14,3.1,10.9,0,7,0z M7,13.4c-3.5,0-6.4-2.9-6.4-6.4
	c0-3.5,2.9-6.4,6.4-6.4c3.5,0,6.4,2.9,6.4,6.4C13.4,10.5,10.5,13.4,7,13.4z M11.1,5.1c0-0.1,0-0.2-0.1-0.3c-0.2-0.2-0.4-0.2-0.6,0
	L6.1,9.2L3.8,6.9C3.7,6.8,3.7,6.8,3.6,6.8s-0.2,0-0.3,0.1C3.2,7,3.2,7.1,3.2,7.2c0,0.1,0,0.2,0.1,0.3L5.8,10c0.2,0.2,0.4,0.2,0.6,0
	L11,5.4C11,5.3,11.1,5.2,11.1,5.1z"
    />
  </svg>
);
